import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import SendButton from "../sendButton/sendButton";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import renderHTML from "react-render-html";

export default class CategoryDiscussionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getCommentsValue();
  }

  getCommentsValue = async () => {
    let activityCount = this.props.data && this.props.data.activityCount;
    this.setState({
      activityCount: activityCount,
    });
  };

  renderButtonActiveDiscuss = () => {
    return (
      <div className="activeDiscussionActionContainer">
        <div className="discussCardShareContainer reverse">
          <div>
            {" "}
            <IconAndText
              text={this.props.t("comments")}
              istext={true}
              value={this.state.activityCount && this.state.activityCount}
            />{" "}
          </div>
          <div>
            {" "}
            <IconAndText
              text={this.props.t("share")}
              onClick={this.props.onClickShare}
              istext={false}
              value={Share}
            />{" "}
          </div>
        </div>
        <div
          className="discussCardButton"
          style={{
            marginTop: `${this.props.isActiveDiscussion && "16px"}`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SendButton size={"small"} text={window.t("toSubject")} />
        </div>
      </div>
    );
  };

  renderButtonForNormalCard = () => {
    return (
      <div className="normalCardActionButtons reverse">
        <div>
          {" "}
          <IconAndText
            onClick={this.props.onClickShare}
            text={this.props.t("share")}
            istext={false}
            value={Share}
          />{" "}
        </div>
        <div>
          {" "}
          <IconAndText
            text={this.props.t("comments")}
            istext={true}
            value={this.state.activityCount && this.state.activityCount}
          />{" "}
        </div>
        <SendButton
          size={"small"}
          fullWidth={true}
          text={window.t("toSubject")}
        />
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="categoryDiscussCardContainer"
          onClick={this.props.onClick}
          style={{
            background: `${this.props.backgroundColor}`,
            cursor: "pointer",
          }}
        >
          <div className="discussCardImgContainer">
            <img
              src={
                (this.props.data &&
                  this.props.data.cover &&
                  this.props.data.cover.original) ||
                "/assets/img/header.png"
              }
              alt="meeting"
              style={{
                width: "100%",
                height: "146px",
                borderRadius: "4px",
              }}
            />
          </div>
          <div className="discussCardContent ">
            <Typography
              role="heading"
              aria-level="2"
              tabIndex="0"
              variant="h6"
              className="whiteColor bold textAlignEnd lineHeightOne reverse wordBreak-word"
            >
              {this.props.data && this.props.data.name}
            </Typography>
            <div
              className={
                this.props.isActiveDiscussion
                  ? "descriptionStaticHeight"
                  : "categoryDiscussDescription"
              }
            >
              <Typography
                variant="body1"
                className="dullWhite textAlignEnd reverse wordBreak-word"
              >
                {this.props.data?.description &&
                  renderHTML(this.props.data?.description)}
              </Typography>
            </div>
            {this.props.isActiveDiscussion
              ? this.renderButtonActiveDiscuss()
              : this.renderButtonForNormalCard()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
