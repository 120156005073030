import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import HeadWithImg from "../headingWithImage/headingWithImage";
import ReadMore from "../readMoreComponent/readMoreComponent";
import UserPill from "../userAndIcon/userPill";
import SubjectAnswer from "../multiSelectionSubject/multiSelectionSubject";
import SendButton from "../sendButton/sendButton";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import { USER_ROLE, WEBSITE_URL } from "../../../constants";
import Auth from "../../common/auth";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import ArenaModal from "../arenaModal/arenaModal";
import FileSaver from "file-saver";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import moment from "moment";
import renderHTML from "react-render-html";
import MultipleChoiceConclusion, {
  OPTIONS,
} from "../multipleChoiceConclusion/multipleChoiceConclusion";
export default class MultiSubject extends MultipleChoiceConclusion {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedOption: [],
      backgroundSticky: false,
      questionSticky: false,
      OPTIONS: OPTIONS,
      unansweredChoices: false,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    this.fetchAndSaveUserDetails();
    this.fetchAccountDetails();
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");

    if (subjectId) {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        let image =
          subjectData &&
          subjectData.data &&
          subjectData.data.cover &&
          subjectData.data.cover.original;
        this.backgroundImg = image || "/assets/img/header.png";
        if (
          subjectData &&
          subjectData.data &&
          subjectData?.data?.intermediateReport
        ) {
          await this.getAndDownloadReport(subjectId);
        }
        this.setState({
          subjectData: subjectData.data,
          loading: false,
          maxOption: subjectData.data && subjectData.data.choice.max,
          minOption: subjectData.data && subjectData.data.choice.min,
        });
      } catch (e) {
        console.log(e);
        this.setState({
          loading: false,
        });
        window.NotificationUtils.showError(window.t("somethingWentWrong"));
        this.props.history.replace(`/`);
      }
    } else {
      this.props.history.replace(`/`);
    }
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails: accountDetails,
    });
    return accountDetails;
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  onClickOption = (option) => {
    let selectedOptionCopy = Object.assign([], this.state.selectedOption);
    let alreadySelected = this.checkIfAlreadySelected(option);

    if (alreadySelected > -1) {
      selectedOptionCopy.splice(alreadySelected, 1);
      this.setState({
        selectedOption: selectedOptionCopy,
      });
    } else {
      if (this.state.selectedOption.length >= this.state.maxOption) {
        selectedOptionCopy.splice(0, 1);
      }
      selectedOptionCopy.push(option.id);
      this.setState({
        selectedOption: selectedOptionCopy,
      });
    }
  };

  checkIfAlreadySelected = (option) => {
    let index = this.state.selectedOption.indexOf(option.id);
    return index;
  };

  onClickSendButton = async () => {
    if (!this.state.selectedOption.length) {
      window.NotificationUtils.showError(
        `${window.t("pleaseSelectMinimum")} ${this.state.minOption} ${window.t(
          "options"
        )} `
      );
      return;
    }
    if (this.state.selectedOption.length > this.state.maxOption) {
      window.NotificationUtils.showError(
        `${window.t("pleaseSelectOnly")} ${this.state.maxOption} ${window.t(
          "options"
        )} `
      );
      return;
    }
    window.NotificationUtils.showInfo(window.t("savingYourOptions"));
    let data = {
      choice: this.state.selectedOption,
    };
    try {
      await window.Arena.database.voteOnSubject(data, this.state.subjectId);
      window.NotificationUtils.showSuccess(window.t("optionSavedSuccessfully"));
      window.location.reload();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  checkIfSeletced = (option) => {
    let result = this.state.selectedOption.includes(option);
    return result;
  };

  renderSubjectAnswer = () => {
    return (
      this.state.subjectData &&
      this.state.subjectData.choice.options.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={
                this.checkIfSeletced(item.id)
                  ? "multiSelectSubjectContainer selectedOption"
                  : "multiSelectSubjectContainer"
              }
            >
              <SubjectAnswer
                data={item}
                onClickOption={this.onClickOption}
                selectedOption={this.state.selectedOption}
                selected={this.checkIfSeletced(item.id)}
              />
            </div>
          </React.Fragment>
        );
      })
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="top"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
            canAdminister={this.state.subjectData?.canAdminister}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="left"
            subjectText={window.t("editSubject")}
            t={this.props.t}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
            canAdminister={this.state.subjectData?.canAdminister}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeSubject = () => {
    if (!Auth.getAdminFlag() && !this.state.subjectData?.canAdminister) {
      window.NotificationUtils.showInfo(window.t("editSubjectNotAllowed"));
      return;
    }
    let subjectId = this.state.subjectData && this.state.subjectData.id;

    this.props.history.push(`edit-subject?subjectId=${subjectId}`);
  };

  onClickAvatar = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(
      `/${accountDetails.slug}/category-discussion?categoryId=${this.state.subjectData.category.id}`
    );
  };
  sendMessage = () => {
    let subjectId = this.state.subjectData.id && this.state.subjectData.id;
    this.props.history.push(`send-message?subjectId=${subjectId}`);
  };
  onClickShareButton = () => {
    let url = `${WEBSITE_URL}${this.state.accountDetails.slug}/multi-subject?subjectId=${this.state.subjectData.id}`;
    window.ShareModal.showShareModal(url);
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };

  renderPopoverContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor justContentFlexEnd"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showAttachmentPopover: showPopover,
    });
  };

  renderTopContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    let role = Auth.getUserRole();

    return (
      <React.Fragment>
        <div className="discussSubjectTopMainContainer">
          <div className="discussSubjectPageTopContent">
            <ClientNameLarge
              imgURL={this.getCategoryImageUrl()}
              name={this.state.subjectData && this.state.subjectData.name}
              isLarge={true}
              onClickAvatar={this.onClickAvatar}
              ariaLabel={window.t("backToCategory")}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="discussSubjecHeaderInfo reverse">
              {attachmentFiles && attachmentFiles.length > 0 && (
                <IconAndText
                  id={"attachments"}
                  text={window.t("attachments")}
                  value={AttchedFile}
                  onClick={this.onClickAttachmentsButton}
                />
              )}
              {role === USER_ROLE.USER && (
                <IconAndText
                  text={window.t("message")}
                  value={Message}
                  onClick={this.sendMessage}
                />
              )}
              <IconAndText
                text={window.t("comments")}
                istext={true}
                value={
                  (this.state.subjectData &&
                    this.state.subjectData.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={window.t("share")}
                value={Share}
                onClick={this.onClickShareButton}
              />
            </div>
          </div>
          {attachmentFiles && attachmentFiles.length > 0 && (
            <ArenaPopover
              className="arenaPopover attachmentPopover"
              toggle={this.onClickIconButton}
              history={this.props.history}
              placement={"bottom"}
              isOpen={this.state.showAttachmentPopover}
              target={"#attachments"}
            >
              <div>{this.renderPopoverContent()}</div>
            </ArenaPopover>
          )}
        </div>
      </React.Fragment>
    );
  };

  getUserName = () => {
    if (
      this.state.userDetails &&
      this.state.userDetails.role !== USER_ROLE.GUEST
    ) {
      return this.state.userDetails.name;
    }
    return window.t("guest");
  };

  renderThankyouNote = () => {
    return (
      <Typography
        variant="h5"
        className="greenColor bold textAlignEnd marginTop-16 reverse"
      >
        {window.t("thankyouForVote")}
      </Typography>
    );
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${
          this.state.subjectData.name
        } ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderBottomContent = () => {
    let isVoted = this.state.subjectData && this.state.subjectData.isVoted;
    let date = moment.utc(new Date());

    let startDate = moment.utc(
      this.state.subjectData && this.state.subjectData.startDate
    );
    if (this.state.subjectData?.intermediateReport && isVoted) {
      return this.renderMultiConclusion();
    }
    if (startDate > date) {
      return (
        <React.Fragment>
          {this.renderEmptyPlaceholder()}
          {this.renderControlPanel()}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="multiSelectMainContainer">
          <div className={`conclusionPageTopContent mobileSticky`}>
            <div
              className="marginBottom12 reverse"
              id="conclusionPageHeaderHeading"
            >
              <Typography
                role="heading"
                aria-level="1"
                variant="h6"
                className="redColor bold textAlignEnd"
              >
                {window.t("subjectKind")}
              </Typography>
            </div>
            <HeadWithImg
              imgURL="/assets/img/question.png"
              text={this.state.subjectData && this.state.subjectData.question}
            />
          </div>
          <div id="multiSelectReadMoreComponent" className="marginTop24">
            <ReadMore
              t={this.props.t}
              text={
                this.state.subjectData &&
                renderHTML(this.state.subjectData.description)
              }
              textData={
                this.state.subjectData && this.state.subjectData.description
              }
            />
          </div>
          <div id="multiSubjectUserAndInfo">
            <div style={{ margin: "26px 0" }}>
              <UserPill user={this.getUserName()} />
            </div>

            <div>
              <Typography
                variant="body1"
                className="dullWhite textAlignEnd reverse"
                tabIndex={0}
                aria-label={`${window.t("selectSubjectNote")}. ${window.t(
                  "minValuesToSelectAre"
                )} ${this.state.minOption}. ${window.t(
                  "maxValuesToSelectAre"
                )} ${this.state.maxOption}`}
              >
                {window.t("selectSubjectNote")} <br />
                {window.t("minValues")} : {this.state.minOption} <br />
                {window.t("maxValues")} : {this.state.maxOption}
              </Typography>
            </div>

            {isVoted && this.renderThankyouNote()}
          </div>

          {!isVoted && (
            <div className="subjectAnswereListContainer">
              {this.renderSubjectAnswer()}
            </div>
          )}
          {!isVoted && (
            <div id="allocationSendButton">
              <SendButton size={"small"} onClick={this.onClickSendButton} />
            </div>
          )}
        </div>
        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
      </React.Fragment>
    );
  };
}
