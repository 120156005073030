import React, { Component } from "react";
import { loadCSS } from "fg-loadcss";
import PaginationComponent from "../pagination/pagination";
import ConclusionContent from "../conclusion/conclusionContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

export default class ConclusionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 1,
      showConclusion: true,
      showConclusionAttachmentPopover: false,
    };
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }

  handleChange = (value) => {
    this.setState({
      selectedPage: value,
    });
  };

  renderEmptyConclusion = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h6"
          className="bold blueColor textAlignCenter"
          style={{ marginTop: "16px" }}
        >
          Admin have not published any conclusion yet
        </Typography>
      </React.Fragment>
    );
  };

  slideRenderer = ({ key, index }) => {
    const currConclusion = this.props.conclusionData?.[
      this.state.selectedPage - 1
    ];

    return (
      <ConclusionContent
        key={key}
        data={currConclusion}
        imgURL={"/assets/img/reading.png"}
      />
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showConclusionAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showConclusionAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showConclusionAttachmentPopover: showPopover,
    });
  };

  renderPopoverContent = () => {
    let attachmentFiles = this.props.conclusionData?.[
      this.state.selectedPage - 1
    ]?.files;

    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  render() {
    let conclusionData = this.props.conclusionData;
    const currConclusion = conclusionData?.[this.state.selectedPage - 1];

    return (
      <React.Fragment>
        <div className="conclusionMainContainer">
          <Typography
            role="heading"
            aria-level="2"
            variant="h6"
            className="redColor textAlignEnd conclusionTitleExtra bold"
            tabIndex={0}
            aria-label={window.t("conclusion")}
          >
            {window.t("conclusion")}
          </Typography>

          {conclusionData && conclusionData.length > 0 ? (
            <VirtualizeSwipeableViews
              index={this.state.selectedPage}
              onChangeIndex={this.handleChange}
              slideRenderer={this.slideRenderer}
              id="conclusionSwipeable"
              aria-label={conclusionData[0].text1}
              tabIndex={0}
            />
          ) : (
            this.renderEmptyConclusion()
          )}

          <div id="conclusionPageNumberContainer">
            <PaginationComponent
              pageCount={conclusionData && conclusionData.length}
              page={this.state.selectedPage}
              handlePageChange={this.handleChange}
            />
            {currConclusion.files?.length > 0 && (
              <IconButton
                id="conclusionDownloadIcon"
                onClick={this.onClickDownload}
              >
                <SvgIcon
                  className="svgBlueColor"
                  id="conclusionAttachments"
                  style={{ color: "#65D2FC" }}
                  component={AttchedFile}
                  viewBox="0 0 512 512"
                />
              </IconButton>
            )}
          </div>
        </div>
        {currConclusion.files?.length > 0 && (
          <ArenaPopover
            className="arenaPopover attachmentPopover conclusionAttachmentPopover"
            toggle={this.onClickIconButton}
            history={this.props.history}
            placement={"bottom"}
            isOpen={this.state.showConclusionAttachmentPopover}
            target={"#conclusionAttachments"}
          >
            <div>{this.renderPopoverContent()}</div>
          </ArenaPopover>
        )}
      </React.Fragment>
    );
  }
}
