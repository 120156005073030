import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import ActiveDiscussionCard from "../activeDiscussionCard/activeDisscusionCard";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import ReadMoreComponent from "../readMoreComponent/readMoreComponent";
import CategoryDiscussionCard from "../categoryDiscussionCard/categoryDiscussionCard";
import Typography from "@material-ui/core/Typography";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile } from "react-device-detect";
import { FORM_TYPE_MAP, SUBJECT_STATUS, WEBSITE_URL } from "../../../constants";
import Auth from "../../common/auth";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PaginationComponent from "../pagination/pagination";
export default class CategoryDiscussionPage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: null,
      subjectData: [],
      subjectCount: null,
      pageNumber: 1,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let categoryId = params.get("categoryId");
    if (!categoryId) {
      this.props.history.replace("/");
    } else {
      try {
        let categoryResponse = await window.Arena.database.fetchCategoryWithId(
          categoryId
        );
        let categoryActivity = await window.Arena.database.fetchCategoryActivity(
          categoryId
        );
        let categoryData = categoryResponse.data;
        await this.fetchSubjects(categoryData);

        this.backgroundImg =
          categoryData && categoryData.cover && categoryData.cover.original;
        let accountDetails = Auth.getAccountDetails();

        this.setState({
          categoryData: categoryData,
          categoryActivity: categoryActivity.data,
          loading: false,
          accountDetails,
        });
      } catch (e) {
        console.error(e);
        this.setState({
          loading: false,
        });
      }
    }
  }

  fetchSubjects = async (categoryData) => {
    this.setState({
      loading: true,
    });
    try {
      let offset = 20 * (this.state.pageNumber - 1);
      let subjectResponse = await window.Arena.database.fetchSubjectsRelatedToAccount(
        categoryData.account.id,
        {
          category: categoryData.id,
          offset: offset,
          limit: 20,
        }
      );
      let subjectData = subjectResponse.data;
      let subjectCount = subjectResponse.data.count;
      this.setState({
        subjectData: subjectData,
        subjectCount,
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
      console.error(e);
      throw e;
    }
  };

  renderCarouselItem = () => {
    let publishedSubjects = this.getPublishedSubject();
    return (
      publishedSubjects &&
      publishedSubjects.map((item, index) => {
        let categoryName = this.getCategoryName(item.type);
        let url = `${WEBSITE_URL}${
          this.state.accountDetails && this.state.accountDetails.slug
        }/${categoryName}?subjectId=${item.id}`;
        return (
          <Carousel.Item key={index}>
            <div style={{ marginBottom: "10px" }}>
              <ActiveDiscussionCard
                t={this.props.t}
                onClickShare={(e) => this.onClickShareButton(e, url)}
                data={item}
                onClick={() => this.onClickSubjectCard(item)}
              />
            </div>
          </Carousel.Item>
        );
      })
    );
  };

  getPublishedSubject = () => {
    let subjects = Object.assign([], this.state.subjectData.items);
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let startDate = moment.utc(item.startDate);
      let endDate = moment.utc(item.endDate);
      return (
        item.status === SUBJECT_STATUS.PUBLISHED &&
        date.isBetween(startDate, endDate)
      );
    });
    return filteredSubjects;
  };

  renderActiveDiscussionComponent = () => {
    let publishedSubject = this.getPublishedSubject();

    return (
      <React.Fragment>
        {publishedSubject.length > 0 ? (
          <Carousel
            nextIcon={<ChevronRightIcon className="blueColor" />}
            prevIcon={<ChevronLeftIcon className="blueColor" />}
            indicators={true}
          >
            {this.renderCarouselItem()}
          </Carousel>
        ) : (
          this.renderEmptyActiveSubject()
        )}
      </React.Fragment>
    );
  };

  renderEmptyActiveSubject = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          className="bold blueColor textAlignCenter"
          style={{ marginTop: "16px" }}
        >
          {this.props.t("noActiveSubject")}
        </Typography>
      </React.Fragment>
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            categoryText={window.t("editCategory")}
            openEditMode={this.openEditModeCategory}
            history={this.props.history}
            placement="top"
            t={this.props.t}
            showSearch={true}
            categoryId={this.state.categoryData?.id}
            canAdminister={this.state.categoryData?.canAdminister}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            categoryText={window.t("editCategory")}
            openEditMode={this.openEditModeCategory}
            history={this.props.history}
            placement="left"
            t={this.props.t}
            showSearch={true}
            categoryId={this.state.categoryData?.id}
            canAdminister={this.state.categoryData?.canAdminister}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeCategory = () => {
    let categoryId = this.state.categoryData && this.state.categoryData.id;
    this.props.history.push(`create-categorie?categoryId=${categoryId}`);
  };

  onClickShareButton = (e, url) => {
    e.stopPropagation();
    window.ShareModal.showShareModal(url);
  };

  onClickSubjectCard = (data) => {
    if (data.choice) {
      return this.props.history.push(`multi-subject?subjectId=${data.id}`);
    }
    if (data.allocation) {
      return this.props.history.push(`allocation?subjectId=${data.id}`);
    }
    this.props.history.push(`discuss-subject?subjectId=${data.id}`);
  };

  onClickFinishedSubjectCard = (data) => {
    if (data.choice) {
      return this.props.history.push(
        `multiple-choice-conclusion?subjectId=${data.id}`
      );
    }
    if (data.allocation) {
      return this.props.history.push(
        `allocation-conclusion?subjectId=${data.id}`
      );
    }
    this.props.history.push(`conclusion?subjectId=${data.id}`);
  };

  renderEmptyClosedSubject = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          className="bold blueColor textAlignCenter"
          style={{ marginTop: "16px" }}
        >
          {this.props.t("noFinishedSubject")}
        </Typography>
      </React.Fragment>
    );
  };

  getCategoryName = (type) => {
    if (type === FORM_TYPE_MAP.discussion) {
      return "discuss-subject";
    }
    if (type === FORM_TYPE_MAP.allocation) {
      return "allocation";
    }
    if (type === FORM_TYPE_MAP.choice) {
      return "multi-subject";
    }
  };

  getFinishedCardsCategoryName = (type) => {
    if (type === FORM_TYPE_MAP.discussion) {
      return "conclusion";
    }
    if (type === FORM_TYPE_MAP.allocation) {
      return "allocation-conclusion";
    }
    if (type === FORM_TYPE_MAP.choice) {
      return "multiple-choice-conclusion";
    }
  };

  renderSubjectCards = () => {
    let finishedSubject = this.getFinishedSubject();
    if (finishedSubject.length <= 0) {
      return this.renderEmptyClosedSubject();
    }
    return (
      finishedSubject &&
      finishedSubject.map((item, index) => {
        let categoryName = this.getFinishedCardsCategoryName(item.type);
        let url = `${WEBSITE_URL}${
          this.state.accountDetails && this.state.accountDetails.slug
        }/${categoryName}?subjectId=${item.id}`;
        return (
          <React.Fragment key={index}>
            <div className="categoryPageCardContainers">
              <CategoryDiscussionCard
                t={this.props.t}
                onClickShare={(e) => this.onClickShareButton(e, url)}
                data={item}
                isActiveDiscussion={false}
                onClick={() => this.onClickFinishedSubjectCard(item)}
              />
            </div>
          </React.Fragment>
        );
      })
    );
  };

  getFinishedSubject = () => {
    let subjects = Object.assign([], this.state.subjectData.items);
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let endDate = moment.utc(item.endDate);
      return item.status === SUBJECT_STATUS.FINISHED || date > endDate;
    });
    return filteredSubjects;
  };

  onClickBackToCategoriesButton = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(`/${accountDetails.slug}`);
  };

  renderBackToCategoriesButton = () => {
    return (
      <Button
        id="backToCategoriesButton"
        onClick={this.onClickBackToCategoriesButton}
        size="small"
        variant="contained"
        endIcon={<ArrowForwardIosIcon style={{ color: "#000" }} />}
        className="reverse"
      >
        {window.t("backToCategories")}
      </Button>
    );
  };

  renderTopContent = () => {
    let url = `${WEBSITE_URL}${
      this.state.accountDetails && this.state.accountDetails.slug
    }/category-discussion?categoryId=${
      this.state.categoryData && this.state.categoryData.id
    }`;
    return (
      <React.Fragment>
        <div id="categoryDiscussionTopContainer">
          <div className="categoryDiscussionPageTopContent">
            <ClientNameLarge
              name={this.state.categoryData && this.state.categoryData.name}
              imgURL={
                (this.state.categoryData &&
                  this.state.categoryData.icon &&
                  this.state.categoryData.icon.original) ||
                "/assets/img/client.png"
              }
              isLarge={true}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="categoryDiscussHeaderInfo reverse">
              {/* <IconAndText text={"Message"}  value={"far fa-envelope"} />    removed for 1st release as client have said in a clarified card for this option (trello)*/}
              <IconAndText
                text={this.props.t("comments")}
                istext={true}
                value={
                  (this.state.categoryActivity &&
                    this.state.categoryActivity.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={this.props.t("share")}
                value={Share}
                onClick={(e) => this.onClickShareButton(e, url)}
              />
            </div>
          </div>
        </div>
        {this.renderBackToCategoriesButton()}
      </React.Fragment>
    );
  };

  renderFutureSubjectCards = () => {
    let futureSubjects = this.getFutureSubject();
    return (
      futureSubjects &&
      futureSubjects.map((item, index) => {
        let categoryName = this.getFinishedCardsCategoryName(item.type);
        let url = `${WEBSITE_URL}${
          this.state.accountDetails && this.state.accountDetails.slug
        }/${categoryName}?subjectId=${item.id}`;
        return (
          <React.Fragment key={index}>
            <div className="categoryPageCardContainers">
              <CategoryDiscussionCard
                t={this.props.t}
                onClickShare={(e) => this.onClickShareButton(e, url)}
                data={item}
                isActiveDiscussion={false}
                onClick={() => this.onClickSubjectCard(item)}
              />
            </div>
          </React.Fragment>
        );
      })
    );
  };

  getFutureSubject = () => {
    let subjects = Object.assign(
      [],
      this.state.subjectData && this.state.subjectData.items
    );
    let date = moment.utc(new Date());
    let filteredSubjects = subjects.filter((item) => {
      let startDate = moment.utc(item.startDate);
      return startDate > date;
    });
    return filteredSubjects;
  };

  renderInPreparationSection = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h6"
          role="heading"
          aria-level="1"
          tabIndex="0"
          aria-label={this.props.t("inPreparation")}
          className="redColor bold textAlignEnd categoryHeadingTypoExtra reverse"
        >
          {this.props.t("inPreparation")}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "26px",
            marginBottom: "64px",
          }}
        >
          <div className="categoryDiscussionCardContainer reverse">
            {this.state.subjectData && this.renderFutureSubjectCards()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleChange = (value) => {
    this.setState(
      {
        pageNumber: value,
      },
      () => this.fetchSubjects(this.state.categoryData)
    );
  };

  renderBottomContent = () => {
    let stateCategoryData = Object.assign({}, this.state.categoryData);
    let futureSubjects = this.getFutureSubject();
    return (
      <React.Fragment>
        <div className="categoryDiscussionPageMainContainer">
          <div className="categoryActiveDiscussionContainer reverse">
            <div className="activeCardAndHeading">
              <Typography
                role="heading"
                aria-level="1"
                tabIndex="0"
                aria-label={this.props.t("activeDiscussion")}
                variant="h6"
                className="redColor bold textAlignEnd categoryHeadingTypoExtra reverse"
              >
                {this.props.t("activeDiscussion")}
              </Typography>
              <div className="activeDiscussionCarousel">
                {this.state.subjectData &&
                  this.renderActiveDiscussionComponent()}
              </div>
            </div>

            <div id="readMoreComponentContainer">
              <ReadMoreComponent
                t={this.props.t}
                text={stateCategoryData.description}
                className="spacedLines"
              />
            </div>
          </div>

          <Typography
            variant="h6"
            role="heading"
            aria-level="1"
            tabIndex="0"
            aria-label={this.props.t("yourInfluences")}
            className="redColor bold textAlignEnd categoryHeadingTypoExtra reverse"
          >
            {this.props.t("yourInfluences")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "26px",
              marginBottom: "64px",
            }}
          >
            <div className="categoryDiscussionCardContainer reverse">
              {this.state.subjectData && this.renderSubjectCards()}
            </div>
          </div>

          {futureSubjects &&
            futureSubjects.length > 0 &&
            this.renderInPreparationSection()}
          <div id="subjectPagePagination">
            <PaginationComponent
              pageCount={Math.ceil(
                this.state.subjectCount && this.state.subjectCount / 20
              )}
              selectedPage={this.state.pageNumber}
              handlePageChange={this.handleChange}
            />
          </div>
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
