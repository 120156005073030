import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import ControlPanel from "../controlPanel/controlPanel";
import SendButton from "../sendButton/sendButton";
import ArenaInput from "../arenaInputField/arenaInput";
import ArenaUploader from "../arenaUploader/arenaUploader";
import ArenaButton from "../arenaButton/arenaButton";
import Auth from "../../common/auth";
import { isBrowser, isMobile } from "react-device-detect";
import ArenaModal from "../arenaModal/arenaModal";
export default class CreateCategory extends CommonStructure {
  constructor(props) {
    super(props);
    console.log("props: ****************************************  ", props);
    this.state = {
      admins: "",
      description: "",
      clientName: "",
      coverURL: "",
      iconURL: "",
      categoryId: "",
      accountDetails: null,
      showDeleteModal: false,
      accountId: "",
    };
    this.backgroundImg = "/assets/img/couple.png";
  }

  async componentDidMount() {
    let querySearchParams = this.props.history.location.search;
    let queryStateParams =
      this.props.history && this.props.history.location?.state?.canAdminister;
    let params = new URLSearchParams(querySearchParams);
    let categoryId = params.get("categoryId");
    console.log("categoryId: **********************   ", categoryId);

    let categoryData = {};
    if (categoryId) {
      try {
        let categoryResponse = await window.Arena.database.fetchCategoryWithId(
          categoryId
        );
        categoryData = categoryResponse.data;
        console.log("categoryData************************** : ", categoryData);
        this.setState({
          admins: categoryData.admins || "",
          description: categoryData.description || "",
          clientName: categoryData.name || "",
          coverURL: (categoryData.cover && categoryData.cover.original) || "",
          iconURL: (categoryData.icon && categoryData.icon.original) || "",
          categoryId: categoryId,
          accountId: categoryData.account && categoryData.account.id,
          loading: false,
        });
      } catch (e) {
        console.error(e);
        this.setState({
          loading: false,
        });
        this.props.history.replace(`/`);
      }
    } else {
      const slug = this.props.history.location.pathname.split("/")[1];
      const account = await this.getAccountDetails(slug);
      this.setState({ accountId: account.id });
    }

    if (
      (categoryId && !categoryData.canAdminister) ||
      (!categoryId && !queryStateParams && !Auth.getAdminFlag())
    ) {
      try {
        setTimeout(() => {
          window.NotificationUtils.showInfo(
            window.t("createSubjectNotAllowed")
          );
        }, 2000);
        this.props.history.push("/");
      } catch (e) {}
    }
    // else{
    //   if(!Auth.getAdminFlag()){
    //     this.props.history.push("/");
    //   }
    // }
    window.addEventListener("keypress", this.keyPressed);
    this.setState({
      loading: true,
    });

    let accountDetails = Auth.getAccountDetails();
    this.setState({
      loading: false,
      accountDetails: accountDetails,
    });
  }

  getAccountDetails = async (slug) => {
    try {
      const accountDetails = await window.Arena.database.fetchAccountDetails(
        slug
      );
      return accountDetails?.data?.items?.find(
        (account) => account.slug === slug
      );
    } catch (e) {
      console.error(e);
    }
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.createCategory();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keypress", this.keyPressed);
  }

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="top"
            editMode={true}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            history={this.props.history}
            placement="left"
            editMode={true}
          />
        </React.Fragment>
      );
    }
  };

  onChangeDropdown = (selectedOption, type) => {
    this.setState({
      [type]: selectedOption,
    });
  };

  onChangeTextfields = (e, type) => {
    this.setState({
      [type]: e.target.value,
    });
  };

  onChangeRadioButton = (e) => {
    this.setState({
      radioValue: e.target.value,
    });
  };

  onChangeDate = (date, type) => {
    this.setState({
      [type]: date,
    });
  };

  handleCheckboxChange = () => {
    this.setState({
      showMoreFields: !this.state.showMoreFields,
    });
  };

  onUploadComlete = (response, type) => {
    this.setState({
      [type]: response.data.original,
    });
  };

  checkErrors() {
    if (!this.state.clientName) {
      window.NotificationUtils.showError(window.t("enterCategoryName"));
      return true;
    } else if (this.state.clientName.length > 64) {
      window.NotificationUtils.showError(window.t("categoryNameMoreThan64"));
      return true;
    }
    if (!this.state.description) {
      window.NotificationUtils.showError(window.t("enterDescription"));
      return true;
    } else if (this.state.description.length > 1000) {
      window.NotificationUtils.showError(window.t("descriptionMoreThan1000"));
      return true;
    }
    if (!this.state.coverURL) {
      window.NotificationUtils.showError(
        window.t("backgroundCoverNotUploaded")
      );
      return true;
    }
    if (!this.state.iconURL) {
      window.NotificationUtils.showError(window.t("iconUrlNotUploaded"));
      return true;
    }
    return false;
  }

  createCategory = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    window.NotificationUtils.showInfo(
      this.state.categoryId
        ? window.t("editingCategory")
        : window.t("creatingCategory")
    );
    let data = {
      admins: this.state.admins,
      account: this.state.accountId,
      name: this.state.clientName,
      description: this.state.description,
      cover: {
        original: this.state.coverURL,
        sizes: {
          "720x360": this.state.coverURL,
        },
      },
      icon: {
        original: this.state.iconURL,
        sizes: {
          "240x240": this.state.iconURL,
        },
      },
    };
    try {
      if (this.state.categoryId) {
        await window.Arena.database.editCategories(data, this.state.categoryId);
        window.NotificationUtils.showSuccess(
          window.t("categoryEditedSuccessfully")
        );
        this.props.history.goBack();
      } else {
        console.log("the data is: ", data);
        await window.Arena.database.createCategories(data);
        window.NotificationUtils.showSuccess(
          window.t("categoryCreatedSuccessfully")
        );
        window.categoryCount = window.categoryCount + 1;
        this.props.history.goBack();
      }
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      console.error(e);

      this.setState({
        checkErrors: false,
      });
    }
  };

  deleteCategory = async () => {
    window.NotificationUtils.showInfo(window.t("deletingCategory"));

    try {
      let categoryId = this.state.categoryId && this.state.categoryId;
      await window.Arena.database.deleteCategory(categoryId);
      window.NotificationUtils.showSuccess(
        window.t("categoryDeletedSuccessfully")
      );
      window.categoryCount = window.categoryCount - 1;
      this.props.history.replace(`/${this.state.accountDetails.slug}`);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  errorInDescription = () => {
    return !this.state.description;
  };

  errorInName = () => {
    return !this.state.clientName;
  };

  renderTopContent = () => {
    if (!this.state.categoryId) {
      return (
        <React.Fragment>
          {this.renderBackButton()}
          <div className="editSubjectTopMainContainer">
            <Typography
              role="heading"
              aria-level="1"
              variant="h4"
              className="dullWhite bold"
              style={{ textAlign: "center" }}
              aria-label={window.t("newCategory")}
              tabIndex={0}
            >
              {window.t("newCategory")}
            </Typography>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.renderBackButton()}
        <div className="editSubjectTopMainContainer">
          <Typography
            role="heading"
            aria-level="1"
            variant="h4"
            className="dullWhite bold"
            style={{ textAlign: "center" }}
            aria-label={this.state.clientName}
            tabIndex={0}
          >
            {this.state.clientName}
          </Typography>
        </div>
      </React.Fragment>
    );
  };

  onClickDelete = () => {
    this.setState({
      showDeleteModal: true,
    });
  };
  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  renderDeleteModal = () => {
    return (
      <ArenaModal
        text={`${window.t("deleteText")} ${this.state.clientName} ?`}
        openArenaModal={this.state.showDeleteModal}
        handleArenaModalClose={this.closeDeleteModal}
        secondaryButtonText={window.t("cancel")}
        primaryButtonText={window.t("approve")}
        onClickSecondaryButton={this.closeDeleteModal}
        onClickPrimaryButton={this.deleteCategory}
        headerTitle={window.t("deleteCategory")}
      />
    );
  };

  renderActionButtons = () => {
    if (!this.state.categoryId) {
      return (
        <React.Fragment>
          <div>
            <SendButton
              extraClass="createButton"
              text={window.t("publishCategory")}
              size={"small"}
              onClick={this.createCategory}
            />
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div id="createCategoryLeftArenaButton">
          <SendButton
            extraClass="createButton"
            text={window.t("publishCategory")}
            size={"small"}
            onClick={this.createCategory}
          />
        </div>

        <div id="createCategoryRightArenaButton">
          <ArenaButton
            size={"small"}
            text={window.t("delete")}
            onClick={this.onClickDelete}
          />
        </div>
      </React.Fragment>
    );
  };

  renderBottomContent = () => {
    let contentHeading = !this.state.categoryId
      ? window.t("createCategory")
      : window.t("editCategory");
    return (
      <React.Fragment>
        <div className="createCategorieBottomMainContainer">
          <div style={{ marginBottom: "26px" }}>
            <Typography
              role="heading"
              aria-level="2"
              variant="h5"
              className="redColor bold"
              style={{ textAlign: "center" }}
              aria-label={contentHeading}
              tabIndex={0}
            >
              {contentHeading}
            </Typography>
          </div>

          <div id="createCategoriInputFieldContainer" className="reverse">
            <div className="createCategoryFieldsContainer">
              <Typography
                variant="body1"
                style={{ marginBottom: "16px" }}
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("generalDescription")}
              </Typography>
              <ArenaInput
                multiline={true}
                rows={6}
                value={this.state.description}
                onChange={(e) => {
                  this.onChangeTextfields(e, "description");
                }}
                error={this.state.checkErrors && this.errorInDescription()}
                helperText={
                  this.state.checkErrors &&
                  this.errorInDescription() &&
                  window.t("invalidDescription")
                }
                ariaLabelText={window.t("generalDescription")}
              />
            </div>

            <div className="createCategoryFieldsContainer">
              <Typography
                variant="body1"
                style={{ marginBottom: "16px" }}
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("categoryName")}
              </Typography>
              <ArenaInput
                value={this.state.clientName}
                onChange={(e) => {
                  this.onChangeTextfields(e, "clientName");
                }}
                error={this.state.checkErrors && this.errorInName()}
                helperText={
                  this.state.checkErrors &&
                  this.errorInName() &&
                  window.t("invalidName")
                }
                ariaLabelText={window.t("categoryName")}
              />
            </div>
          </div>

          <div id="createCategoriUploaderContainer" className="reverse">
            <div className="createCategoryFieldsContainer">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("backgroundImage")}
              </Typography>
              <div style={{ marginTop: "16px" }}>
                <ArenaUploader
                  multiple={false}
                  fileURL={this.state.coverURL && this.state.coverURL}
                  accept={"images"}
                  maxSize={2 * 1024 * 1024}
                  onUploadComplete={(response) => {
                    this.onUploadComlete(response, "coverURL");
                  }}
                  ariaLabelText={window.t("backgroundImage")}
                />
              </div>
              <div style={{ marginTop: "26px", padding: "0 52px" }}>
                <Typography
                  variant="body1"
                  className="dullWhite textAlignCenter"
                >
                  {window.t("backgroundImageSize")}
                </Typography>
              </div>
            </div>

            <div className="createCategoryFieldsContainer">
              <Typography
                variant="body1"
                className="dullWhite bold textAlignEnd reverse"
              >
                {window.t("categoryLogo")}
              </Typography>
              <div id="createCategoryRoundUploader">
                <ArenaUploader
                  multiple={false}
                  roundUploader={true}
                  fileURL={this.state.iconURL && this.state.iconURL}
                  accept={"images"}
                  maxSize={2 * 1024 * 1024}
                  onUploadComplete={(response) => {
                    this.onUploadComlete(response, "iconURL");
                  }}
                  ariaLabelText={window.t("categoryLogo")}
                />
              </div>
              <div style={{ marginTop: "26px", padding: "0 52px" }}>
                <Typography
                  variant="body1"
                  className="dullWhite textAlignCenter"
                >
                  {window.t("recommendedSize")}
                </Typography>
              </div>
            </div>
          </div>

          <div id="createCategoriButtonContainer">
            {this.renderActionButtons()}
          </div>
        </div>
        {this.renderDeleteModal()}
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
