import React, { Component } from "react";
import "./App.css";
import "./directions.css";
import "./direction-responsive.css";
import { loadCSS } from "fg-loadcss";
import { Route, Switch, BrowserRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
import database from "./utils/database";
import LandingPage from "../src/components/ui/landingPage/landingPage";
import Login from "../src/components/authentication/login/login";
import Signup from "../src/components/authentication/signup/signup";
import EditProfile from "./components/ui/editProfile/editProfile";
import ClientsRoot from "./components/ui/clientsRoot/clientsRoots";
import ResetPassword from "./components/ui/resetPassword/resetPassword";
import Categories from "./components/ui/categories/categories";
import SendMessage from "./components/ui/sendMessage/sendMessage";
import CategoryDiscussionPage from "./components/ui/categoryDiscussionPage/categoryDiscussionPage";
import ConclusionPage from "./components/ui/conclusionPage/conclusionPage";
import MyCommentsPage from "./components/ui/myCommentsPage/myCommentsPage";
import DiscussSubject from "./components/ui/discussSubject/discussSubject";
import MultiSelect from "./components/ui/multiSelectSubject/multiSelectPage";
import EditSubject from "./components/ui/editSubject/editSubject";
import EmailForResetPassword from "./components/ui/emailForResetPassword/emailForResetPassword";
import FacebookCallback from "../src/components/authentication/callback/facebookCallback";
import GoogleCallback from "../src/components/authentication/callback/googleCallback";
import Snackbar from "./components/common/snackbar";
import ShareModal from "./components/common/shareModal";
import Auth from "./components/common/auth";
import AllocationPage from "./components/ui/allocationPage/allocationPage";
import CreateCategorie from "./components/ui/createCategorie/createCategory";
import Spinner from "@material-ui/core/CircularProgress";
import AllocationConclusion from "./components/ui/allocationConclusion/allocationConclusion";
import MultipleChoiceConclusion from "./components/ui/multipleChoiceConclusion/multipleChoiceConclusion";
import { USER_ROLE } from "./constants";
import theme from "../src/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import SearchPage from "./components/ui/searchPage/searchPage";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Terms from "./components/ui/terms/terms";
import AccessibilityDisclaimer from "./components/ui/accessibilityDisclaimer/accessibilityDisclaimer";
import NotFound from "./components/ui/notFound/notFound";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const jwtDecode = require("jwt-decode");

const hist = createBrowserHistory();
window.NotificationUtils = {};
window.ShareModal = {};
window.Arena = {};
window.Arena.database = database;
window.role = "";
window.categoryCount = 0;
window.chats = {};
const RouterWrapper = (props) => {
  let hist1 = useHistory();
  return <props.component history={hist1} {...props} />;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      loading: true,
      slugFound: true,
    };
    this.snackbarRef = React.createRef();
    this.shareModalRef = React.createRef();
  }

  componentDidMount = async () => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    window.NotificationUtils.showSuccess = (message, extras = {}) => {
      this.snackbarRef.current.showSuccess(message, extras);
    };
    window.NotificationUtils.showError = (message, extras = {}) => {
      this.snackbarRef.current.showError(message, extras);
    };
    window.NotificationUtils.showWarning = (message, extras = {}) => {
      this.snackbarRef.current.showWarning(message, extras);
    };
    window.NotificationUtils.showInfo = (message, extras = {}) => {
      this.snackbarRef.current.showInfo(message, extras);
    };

    window.ShareModal.showShareModal = (url) => {
      this.shareModalRef.current.showModal(url);
    };

    let userToken = Auth.getToken();

    if (!userToken) {
      let response = await window.Arena.database.guestSignup();
      window.NotificationUtils.showInfo(window.t("signedInAsGuestUser"));
      Auth.storeUserData(response);
    }

    let userDetails = await this.getUserData();
    let isAdmin = Auth.getAdminFlag();
    if (isAdmin) {
      window.role = USER_ROLE.SYSTEM_ADMIN;
    } else {
      if (userDetails && userDetails.role === USER_ROLE.GUEST) {
        window.role = USER_ROLE.GUEST;
      } else {
        window.role = USER_ROLE.USER;
      }
    }

    if (window.role !== USER_ROLE.GUEST) {
      window.chats.fetchChats = async () => {
        let chats = await this.fetchChats();
        return chats;
      };
    }

    if (userDetails) {
      Auth.storeUserDetails(userDetails);
      this.setState({
        userDetails: userDetails,
      });
    }

    let path = hist.location.pathname;
    let routes = path.split("/");
    let slug = routes[1];
    if (slug === "") {
      return;
    }
    if (slug === "callbacks") {
      return;
    }

    if (slug === "terms" || slug === "accessibility" || slug === "404") {
      hist.replace({
        pathname: `/${slug}`,
      });
      return;
    }
    let accountDetails = await this.getAccountDetails(slug);
    if (!accountDetails) {
      window.NotificationUtils.showError(window.t("slugNotFound"));
      hist.replace({
        pathname: "/404",
        search: "",
      });
      return;
    }
    Auth.storeAccountDetails(accountDetails);
    this.setState({
      accountDetails: accountDetails,
      loading: false,
    });

    if (isAdmin) {
      let count = await this.getCategoriesCount(accountDetails);
      if (count) {
        window.categoryCount = count;
      }
    }
    window.NotificationUtils.showInfo = (message, extras = {}) => {
      this.snackbarRef.current.showInfo(message, extras);
    };
  };

  fetchChats = async () => {
    let response = await window.Arena.database.fetchChats();
    if (
      response &&
      response.data &&
      response.data.items &&
      response.data.items.length > 0
    ) {
      return response.data.items;
    } else {
      return null;
    }
  };

  getCategoriesCount = async (accountDetails) => {
    let data = await window.Arena.database.fetchCategoriesRelatedToAccount(
      accountDetails.id,
      0
    );
    if (data && data.data && data.data.items && data.data.items.length) {
      let count = data.data.items.length;
      return count;
    }
    return null;
  };

  getAccountDetails = async (slug) => {
    try {
      let accountDetails = await window.Arena.database.fetchAccountDetails(
        slug
      );

      if (!accountDetails || accountDetails.data.items.length < 1) {
        //TODO Do something

        this.setState({
          slugFound: false,
        });
        return;
      }
      return accountDetails.data.items[0];
    } catch (e) {
      console.error(e);
      this.setState({
        slugFound: false,
      });
    }
  };

  getUserData = async () => {
    let userData = this.getUserId();
    if (!userData) {
      return null;
    }
    try {
      let response = await window.Arena.database.fetchUserWithId(
        userData.userId
      );
      return response.data;
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  getUserId = () => {
    try {
      let accessToken = Auth.getToken();
      if (!accessToken) {
        return null;
      }
      let userData = jwtDecode(accessToken);
      return userData;
    } catch (err) {
      console.log("error parsing access token:", err);
    }
  };

  renderLoader() {
    return (
      <div style={{ position: "absolute" }}>
        <Spinner size="24px" style={{ color: "#65D2FC" }} />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    window.t = t;
    document.title = window.t("Arena - Social Knowledge");
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
      >
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            {
              <BrowserRouter
                history={hist}
                user={this.state.userDetails && this.state.userDetails}
              >
                {this.state.slugFound ? (
                  <Switch>
                    <Route path="/terms">
                      <RouterWrapper t={t} component={Terms} />
                    </Route>
                    <Route path="/accessibility">
                      <RouterWrapper
                        t={t}
                        component={AccessibilityDisclaimer}
                      />
                    </Route>
                    <Route exact path="/404">
                      <RouterWrapper t={t} component={NotFound} />
                    </Route>
                    <Route exact path="/">
                      <RouterWrapper t={t} component={ClientsRoot} />
                    </Route>
                    {/* REVERT THIS 
                                        <Route exact path="/clients-root">
                                          <RouterWrapper t={t} component={ClientsRoot} />
                                        </Route>
                                        <Route exact path="/">
                                          <RouterWrapper t={t} component={LandingPage} />
                                        </Route> */}
                    <Route path="/callbacks/facebook">
                      <RouterWrapper t={t} component={FacebookCallback} />
                    </Route>
                    <Route path="/callbacks/google">
                      <RouterWrapper t={t} component={GoogleCallback} />
                    </Route>
                    <Route exact path="/:slug">
                      <RouterWrapper t={t} component={Categories} />
                    </Route>
                    <Route exact path="/:slug/welcomePage">
                      <RouterWrapper
                        t={t}
                        component={LandingPage}
                        user={this.state.userDetails && this.state.userDetails}
                      />
                    </Route>
                    <Route exact path={`/:slug/signup`}>
                      <RouterWrapper
                        t={t}
                        component={withGoogleReCaptcha(Signup)}
                      />
                    </Route>
                    <Route exact path={`/:slug/login`}>
                      <RouterWrapper
                        t={t}
                        component={withGoogleReCaptcha(Login)}
                      />
                    </Route>
                    <Route exact path="/:slug/edit-profile">
                      <RouterWrapper t={t} component={EditProfile} />
                    </Route>

                    <Route exact path="/:slug/reset-password">
                      <RouterWrapper t={t} component={ResetPassword} />
                    </Route>
                    {/* <Route exact path={`/:slug/categories`}>
                                          <RouterWrapper component={Categories} />
                                        </Route> */}
                    <Route exact path="/:slug/send-message">
                      <RouterWrapper t={t} component={SendMessage} />
                    </Route>
                    <Route exact path="/:slug/category-discussion">
                      <RouterWrapper t={t} component={CategoryDiscussionPage} />
                    </Route>
                    <Route exact path="/:slug/conclusion">
                      <RouterWrapper t={t} component={ConclusionPage} />
                    </Route>
                    <Route exact path="/:slug/my-comments">
                      <RouterWrapper t={t} component={MyCommentsPage} />
                    </Route>
                    <Route exact path="/:slug/discuss-subject">
                      <RouterWrapper
                        t={t}
                        component={withGoogleReCaptcha(DiscussSubject)}
                      />
                    </Route>
                    <Route exact path="/:slug/multi-subject">
                      <RouterWrapper t={t} component={MultiSelect} />
                    </Route>
                    <Route exact path="/:slug/enter-email">
                      <RouterWrapper t={t} component={EmailForResetPassword} />
                    </Route>

                    <Route exact path="/:slug/edit-subject">
                      <RouterWrapper t={t} component={EditSubject} />
                    </Route>
                    <Route exact path="/:slug/allocation">
                      <RouterWrapper t={t} component={AllocationPage} />
                    </Route>
                    <Route exact path="/:slug/create-categorie">
                      <RouterWrapper t={t} component={CreateCategorie} />
                    </Route>
                    <Route exact path="/:slug/allocation-conclusion">
                      <RouterWrapper t={t} component={AllocationConclusion} />
                    </Route>
                    <Route exact path="/:slug/multiple-choice-conclusion">
                      <RouterWrapper
                        t={t}
                        component={MultipleChoiceConclusion}
                      />
                    </Route>
                    <Route exact path="/:slug/search">
                      <RouterWrapper t={t} component={SearchPage} />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/terms">
                      <RouterWrapper t={t} component={Terms} />
                    </Route>
                    <Route exact path="/accessibility">
                      <RouterWrapper
                        t={t}
                        component={AccessibilityDisclaimer}
                      />
                    </Route>
                    <Route exact path="/404">
                      <RouterWrapper t={t} component={NotFound} />
                    </Route>
                    <Route path="/">
                      <RouterWrapper t={t} component={ClientsRoot} />
                    </Route>
                  </Switch>
                )}
              </BrowserRouter>
            }
            <Snackbar ref={this.snackbarRef} />
            <ShareModal t={this.props.t} ref={this.shareModalRef} />
          </StylesProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    );
  }
}
App = withTranslation()(App);
export default App;
