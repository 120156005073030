import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import Typography from "@material-ui/core/Typography";
import HeadWithImg from "../headingWithImage/headingWithImage";
import Comment from "../comment/comment";
import CommentFilter from "../commentFilter/commentFilter";
import Divider from "@material-ui/core/Divider";
import ReadMore from "../readMoreComponent/readMoreComponent";
import ConclusionComponent from "../conclusion/conclusionComponent";
import ControlPanel from "../controlPanel/controlPanel";
import SubComment from "../subComment/subComment";
import { isBrowser, isMobile } from "react-device-detect";
import { USER_ROLE, SUBJECT_STATUS, WEBSITE_URL } from "../../../constants";
import Auth from "../../common/auth";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import IconAndText from "../categoryMsgAndShare/shareAndMsg";
import ArenaModal from "../arenaModal/arenaModal";
import FileSaver from "file-saver";
import ArenaPopover from "../arenaPopover/arenaPopover";
import Link from "@material-ui/core/Link";
import { ReactComponent as AttchedFile } from "../../../icons/AttchedFile.svg";
import { ReactComponent as Message } from "../../../icons/Message.svg";
import { ReactComponent as Share } from "../../../icons/Share.svg";
import renderHTML from "react-render-html";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";
export default class ConclusionPage extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      backgroundSticky: false,
      questionSticky: false,
      showAttachmentPopover: false,
      underlinePopularity: false,
      underlineDate: false,
      commentsCount: null,
      subjectComments: [],
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    this.fetchAndSaveUserDetails();
    let querySearchParams = this.props.history.location.search;
    let params = new URLSearchParams(querySearchParams);
    let subjectId = params.get("subjectId");

    if (!subjectId) {
      this.props.history.replace(`/`);
    } else {
      this.setState({
        subjectId: subjectId,
      });
      try {
        let subjectData = await window.Arena.database.fetchSubjectWithId(
          subjectId
        );
        this.fetchSubjectComments(subjectId);
        let image =
          subjectData &&
          subjectData.data &&
          subjectData.data.cover &&
          subjectData.data.cover.original;
        this.backgroundImg = image || "/assets/img/header.png";

        if (
          subjectData &&
          subjectData.data &&
          subjectData.data.status === SUBJECT_STATUS.FINISHED
        ) {
          this.getAndDownloadReport(subjectId);
        }

        let conclusionData = subjectData.data?.conclusion ?? [];

        if (subjectData.data?.conclusionFiles) {
          // convert old conclusion-files to new structure
          // conclusionData = conclusionData.map((c) => ({
          //   ...c,
          //   files: (c.files || []).concat(subjectData.data.conclusionFiles),
          // }));
          delete subjectData.data.conclusionFiles;
        }

        this.setState({
          subjectData: subjectData.data,
          conclusionData: conclusionData,
          loading: false,
        });
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError(window.t("somethingWentWrong"));
        this.props.history.replace(`/`);
      }
    }
  };

  fetchSubjectComments = async (subjectId) => {
    try {
      let offset = this.state.subjectComments.length;
      let subjectCommentsData = await window.Arena.database.fetchSubjectComments(
        subjectId,
        offset
      );
      let commentsCount = subjectCommentsData.data.count;
      let commentData =
        (subjectCommentsData &&
          subjectCommentsData.data &&
          subjectCommentsData.data.items) ||
        [];
      commentData = this.state.subjectComments.concat(commentData);
      this.setState({
        commentsCount: commentsCount,
        subjectComments: commentData,
      });
    } catch (e) {
      throw e;
    }
  };

  fetchAccountDetails = () => {
    let accountDetails = Auth.getAccountDetails();
    this.setState({
      accountDetails: accountDetails,
    });
    return accountDetails;
  };

  fetchAndSaveUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
    });
    return userDetails;
  };

  getAndDownloadReport = async (subjectId) => {
    try {
      await window.Arena.database.fetchSubjectReport(subjectId);
      // await window.Arena.database.downloadSubjectReport(subjectId)
    } catch (e) {
      console.error(e);
    }
  };

  renderComments = () => {
    let subjectExperts = this.state.subjectData?.experts;
    return (
      <InfiniteScroll
        dataLength={this.state.subjectComments.length} //This is important field to render the next data
        next={() => this.fetchSubjectComments(this.state.subjectId)}
        hasMore={this.state.commentsCount > this.state.subjectComments.length}
        loader={
          <div className="textAlignCenter">
            <Spinner
              style={{ marginBottom: "24px", color: "#65D2FC" }}
              size="22px"
            />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginBottom: "24px" }}
          >
            {window.t("noMoreCommentsToShow")}
          </Typography>
        }
      >
        {this.state.subjectComments &&
          this.state.subjectComments.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Comment
                  data={item}
                  onClickDelete={this.onClickDelete}
                  index={index}
                  showSendButton={false}
                  addReplyCommentLocally={this.addReplyCommentLocally}
                  subjectExperts={subjectExperts}
                />
                {item.replies && this.renderReplyComments(item.replies, index)}
                <Divider
                  aria-hidden="true"
                  className="commentListDivider"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "1px",
                  }}
                />
              </React.Fragment>
            );
          })}
      </InfiniteScroll>
    );
  };

  renderReplyComments = (subComments, parentIndex) => {
    return (
      subComments &&
      subComments.map((item, index) => {
        return (
          <React.Fragment>
            <SubComment
              data={item}
              index={index}
              parentCommentIndex={parentIndex}
            />
          </React.Fragment>
        );
      })
    );
  };

  renderControlPanel = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="top"
            subjectText={window.t("editSubject")}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
            canAdminister={this.state.subjectData?.canAdminister}
          />
        </React.Fragment>
      );
    }
    if (isBrowser) {
      return (
        <React.Fragment>
          <ControlPanel
            openEditMode={this.openEditModeSubject}
            history={this.props.history}
            placement="left"
            subjectText={window.t("editSubject")}
            onClickDownloadReport={this.onClickDownloadReport}
            subjectId={this.state.subjectId}
            showSearch={true}
            categoryId={this.state.subjectData?.category?.id}
            canAdminister={this.state.subjectData?.canAdminister}
          />
        </React.Fragment>
      );
    }
  };

  openEditModeSubject = () => {
    if (!Auth.getAdminFlag() && !this.state.subjectData?.canAdminister) {
      window.NotificationUtils.showInfo(window.t("editSubjectNotAllowed"));
      return;
    }
    let subjectId = this.state.subjectData && this.state.subjectData.id;
    this.props.history.push(`edit-subject?subjectId=${subjectId}`);
  };

  onClickShareButton = () => {
    let accountDetails = Auth.getAccountDetails();
    let url = `${WEBSITE_URL}${accountDetails.slug}/conclusion?subjectId=${this.state.subjectData.id}`;
    window.ShareModal.showShareModal(url);
  };

  removeCommentLocally = (index) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    commentCopy.splice(index, 1);
    this.setState({
      subjectComments: commentCopy,
    });
  };

  onClickDelete = async (singleCommentId, index) => {
    window.NotificationUtils.showInfo(window.t("initiatingCommentDeletion"));
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.deleteComment(commentId);
      this.removeCommentLocally(index);
      window.NotificationUtils.showSuccess(
        window.t("commentDeletedSuccessfully")
      );
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  onClickLike = async (
    singleCommentId,
    index,
    likeCount,
    subComment,
    parentIndex
  ) => {
    window.NotificationUtils.showInfo(window.t("processingYourRequest"));
    this.setState({
      showAnimation: true,
    });
    let data = {
      liked: true,
    };
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.likeComment(commentId, data);
      subComment
        ? this.addReplyCommentLikeLocally(index, parentIndex, likeCount, true)
        : this.addCommentLikeLocally(index, likeCount, true);
      window.NotificationUtils.showSuccess(window.t("likedComment"));
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  onClickUnLike = async (
    singleCommentId,
    index,
    likeCount,
    subComment,
    parentIndex
  ) => {
    window.NotificationUtils.showInfo(window.t("processingYourRequest"));
    let data = {
      liked: false,
    };
    let commentId = singleCommentId;
    if (!commentId) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
      return;
    }
    try {
      await window.Arena.database.likeComment(commentId, data);
      subComment
        ? this.addReplyCommentLikeLocally(index, parentIndex, likeCount, false)
        : this.addCommentLikeLocally(index, likeCount, false);
      window.NotificationUtils.showSuccess(window.t("unLikedComment"));
    } catch (e) {
      window.NotificationUtils.showError(window.t("somethingWentWrong"));
    }
  };

  addCommentLikeLocally = (index, likesCount, liked) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (liked) {
      commentCopy[index].likesCount = likesCount + 1;
    } else {
      commentCopy[index].likesCount = likesCount - 1;
    }
    this.setState({
      subjectComments: commentCopy,
    });
  };

  addReplyCommentLikeLocally = (index, parentIndex, likesCount, liked) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    if (liked) {
      commentCopy[parentIndex].replies[index].likesCount = likesCount + 1;
    } else {
      commentCopy[parentIndex].replies[index].likesCount = likesCount - 1;
    }
    this.setState({
      subjectComments: commentCopy,
    });
  };

  addReplyCommentLocally = (replyComment, index, replyCount) => {
    let commentCopy = Object.assign([], this.state.subjectComments);
    commentCopy[index].replies.unshift(replyComment);
    commentCopy[index].repliesCount = replyCount + 1;
    this.setState({
      subjectComments: commentCopy,
    });
  };

  sortCommentsPopularity = () => {
    let commentsCopy = Object.assign([], this.state.subjectComments);
    if (!commentsCopy.length) {
      window.NotificationUtils.showInfo(window.t("noCommentSort"));
      return;
    }
    commentsCopy.sort((a, b) => {
      if (a.likesCount <= b.likesCount) return 1;
      if (a.likesCount >= b.likesCount) return -1;
      return 0;
    });
    this.setState({
      subjectComments: commentsCopy,
      underlinePopularity: true,
      underlineDate: false,
    });
    window.NotificationUtils.showInfo(window.t("commentSortedByPopularity"));
  };

  sortCommentsDate = () => {
    let commentsCopy = Object.assign([], this.state.subjectComments);

    if (!commentsCopy.length) {
      window.NotificationUtils.showInfo(window.t("noCommentSort"));
      return;
    }
    commentsCopy.sort((a, b) => {
      if (a.createdAt <= b.createdAt) return 1;
      if (a.createdAt >= b.createdAt) return -1;
      return 0;
    });
    this.setState({
      subjectComments: commentsCopy,
      underlinePopularity: false,
      underlineDate: true,
    });
    window.NotificationUtils.showInfo(window.t("commentSortedByDate"));
  };

  onClickAvatar = () => {
    let accountDetails = Auth.getAccountDetails();
    this.goBack(
      `/${accountDetails.slug}/category-discussion?categoryId=${this.state.subjectData.category.id}`
    );
  };
  sendMessage = () => {
    let subjectId = this.state.subjectData.id && this.state.subjectData.id;
    this.props.history.push(`send-message?subjectId=${subjectId}`);
  };

  getCategoryImageUrl = () => {
    let categoryData =
      this.state.subjectData && this.state.subjectData.category;
    if (categoryData && categoryData.icon && categoryData.icon.sizes) {
      return categoryData.icon.sizes["240x240"];
    }
    return "";
  };

  renderPopoverContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    if (attachmentFiles && attachmentFiles.length < 1) {
      return (
        <Typography className="whiteColor">
          {window.t("noAttachedFiles")}
        </Typography>
      );
    }
    return (
      attachmentFiles &&
      attachmentFiles.map((file) => {
        return (
          <Link
            className="displayFlex marginBottom8 blueColor justContentFlexEnd"
            href={file.url}
            target="_blank"
          >
            {file.name}
          </Link>
        );
      })
    );
  };

  onClickAttachmentsButton = () => {
    this.setState({
      showAttachmentPopover: true,
    });
  };

  onClickIconButton = () => {
    let showPopover = false;
    if (this.state.showAttachmentPopover) {
      showPopover = false;
    } else {
      showPopover = true;
    }
    this.setState({
      showAttachmentPopover: showPopover,
    });
  };

  renderTopContent = () => {
    let attachmentFiles =
      this.state.subjectData && this.state.subjectData.files;
    let role = Auth.getUserRole();

    return (
      <React.Fragment>
        <div className="discussSubjectTopMainContainer">
          <div className="discussSubjectPageTopContent">
            <ClientNameLarge
              imgURL={this.getCategoryImageUrl()}
              name={this.state.subjectData && this.state.subjectData.name}
              isLarge={true}
              onClickAvatar={this.onClickAvatar}
              ariaLabel={window.t("backToCategory")}
            />
          </div>
          <div id="headerInfoContainer" className="reverse">
            <div className="discussSubjecHeaderInfo reverse">
              {attachmentFiles && attachmentFiles.length > 0 && (
                <IconAndText
                  id={"attachments"}
                  text={window.t("attachments")}
                  value={AttchedFile}
                  onClick={this.onClickAttachmentsButton}
                />
              )}
              {role === USER_ROLE.USER && (
                <IconAndText
                  text={window.t("message")}
                  value={Message}
                  onClick={this.sendMessage}
                />
              )}
              <IconAndText
                text={window.t("comments")}
                istext={true}
                value={
                  (this.state.subjectData &&
                    this.state.subjectData.activityCount) ||
                  0
                }
              />
              <IconAndText
                text={window.t("share")}
                value={Share}
                onClick={this.onClickShareButton}
              />
            </div>
          </div>
        </div>
        {attachmentFiles && attachmentFiles.length > 0 && (
          <ArenaPopover
            className="arenaPopover attachmentPopover"
            toggle={this.onClickIconButton}
            history={this.props.history}
            placement={"bottom"}
            isOpen={this.state.showAttachmentPopover}
            target={"#attachments"}
          >
            <div>{this.renderPopoverContent()}</div>
          </ArenaPopover>
        )}
      </React.Fragment>
    );
  };

  closeDownloadConfirmModal = () => {
    this.setState({
      showDownloadConfirmModal: false,
    });
  };
  renderDownloadConfirmModal = () => {
    return (
      <ArenaModal
        text={`${window.t("downloadReportText")} ${
          this.state.subjectData.name
        } ?`}
        openArenaModal={this.state.showDownloadConfirmModal}
        handleArenaModalClose={this.closeDownloadConfirmModal}
        secondaryButtonText={window.t("cancel")}
        onClickSecondaryButton={this.closeDownloadConfirmModal}
        onClickPrimaryButton={this.getDownloadReport}
        headerTitle={window.t("downloadReport")}
      />
    );
  };

  getDownloadReport = async () => {
    let result = await window.Arena.database.downloadDiscussSubjectReport(
      this.state.subjectId
    );
    FileSaver.saveAs(result.data, `report-${this.state.subjectId}.xlsx`);
    this.setState({
      showDownloadConfirmModal: false,
    });
  };

  onClickDownloadReport = () => {
    this.setState({
      showDownloadConfirmModal: true,
    });
  };

  renderEmptyConclusion = () => {
    return (
      <div className="emptyConclusionContainer">
        <Typography
          variant="h6"
          className="bold blueColor textAlignCenter"
          style={{ marginTop: "16px" }}
        >
          {window.t("noConclusionsPublishedYet")}
        </Typography>
      </div>
    );
  };

  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div id="concusionPageBottomMainContainer">
          <div className={`conclusionPageTopContent mobileSticky`}>
            <div
              className="marginBottom12 reverse"
              id="conclusionPageHeaderHeading"
            >
              <Typography
                role="heading"
                aria-level="1"
                variant="h6"
                className="redColor bold textAlignEnd"
              >
                {window.t("subjectKind")}
              </Typography>
            </div>
            <HeadWithImg
              imgURL="/assets/img/question.png"
              text={this.state.subjectData && this.state.subjectData.question}
            />
          </div>
          <div
            id="conclusionPageContainer"
            className="insideSticky marginTop24 reverse"
          >
            <div className="conclusionPageCommentContainer commentConclusionFlexContainer zIndex1Mobile">
              <Typography
                role="heading"
                aria-level="2"
                variant="h6"
                className="redColor bold textAlignEnd conclusionTitleExtra reverse zIndex0"
              >
                {window.t("titleComment")}
              </Typography>
              <div
                id="filterContainer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "16px 0",
                }}
                className="reverse zIndex0"
              >
                <CommentFilter
                  onClickPopularity={this.sortCommentsPopularity}
                  onclickDate={this.sortCommentsDate}
                  underlineDate={this.state.underlineDate}
                  underlinePopularity={this.state.underlinePopularity}
                />
              </div>
              <div className="conclusionCommentsListContainer">
                {this.renderComments()}
              </div>
            </div>
            <div className="conclusionPageConclusionContainer commentConclusionFlexContainer">
              <div className="marginTopBottom">
                <ReadMore
                  text={
                    this.state.subjectData &&
                    this.state.subjectData.description &&
                    renderHTML(this.state.subjectData.description)
                  }
                  textData={
                    this.state.subjectData && this.state.subjectData.description
                  }
                />
              </div>
              {this.state.conclusionData &&
              this.state.conclusionData.length &&
              this.state.conclusionData[0].title !== "" ? (
                <div>
                  <ConclusionComponent
                    subjectData={this.state.subjectData}
                    subjectId={this.state.subjectId}
                    conclusionData={this.state.conclusionData}
                  />
                </div>
              ) : (
                this.renderEmptyConclusion()
              )}
            </div>
          </div>
        </div>
        {this.renderControlPanel()}
        {this.state.showDownloadConfirmModal &&
          this.renderDownloadConfirmModal()}
      </React.Fragment>
    );
  };
}
