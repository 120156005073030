import React, { Component } from "react";
import BackupIcon from "@material-ui/icons/Backup";
import Spinner from "@material-ui/core/CircularProgress";
import Dropzone from "react-dropzone";

export default class ArenaUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileForUpload: null,
      loading: false,
      uploadedFileURL: null,
    };
  }

  onFileChange = (file) => {
    let files = Array.from(file);
    this.setState(
      {
        selectedFileForUpload: files,
        loading: true,
      },
      this.uploadFile
    );
  };

  uploadFile = () => {
    if (this.props.docUploader) {
      this.uploadDoc();
    } else {
      this.uploadImage();
    }
  };

  uploadDoc = async () => {
    this.setState({
      loading: true,
    });
    try {
      let fileData =
        this.state.selectedFileForUpload && this.state.selectedFileForUpload;
      for (let file of fileData) {
        let formData = new FormData();

        formData.append("file", file);
        let response = await window.Arena.database.uploadDocument(formData);
        if (!response.data) {
          window.NotificationUtils.showError(
            window.t("somethingWentWrongWhileUploading")
          );
          return;
        }
        window.NotificationUtils.showSuccess(
          window.t("fileUploadedSuccessfully")
        );
        this.setState({
          uploadedFileURL: response && response.data && response.data.original,
        });
        this.props.onUploadComplete && this.props.onUploadComplete(response);
      }
      this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(
        window.t("somethingWentWrongWhileUploading")
      );
      this.setState({
        loading: false,
        selectedFileForUpload: null,
      });
    }
  };
  uploadImage = async () => {
    this.setState({
      loading: true,
    });
    try {
      let fileData =
        this.state.selectedFileForUpload && this.state.selectedFileForUpload;
      for (let file of fileData) {
        let formData = new FormData();
        let cropData = [200, 200, 0, 400, 400, 400];
        formData.append("file", fileData);
        formData.append("crop[]", cropData);
        formData.append("file", file);
        let response = await window.Arena.database.uploadImage(formData);
        window.NotificationUtils.showSuccess(
          window.t("fileUploadedSuccessfully")
        );

        this.setState({
          uploadedFileURL: response && response.data && response.data.original,
        });
        this.props.onUploadComplete && this.props.onUploadComplete(response);
      }
      this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(
        window.t("somethingWentWrongWhileUploading")
      );
      this.setState({
        loading: false,
        selectedFileForUpload: null,
      });
    }
  };

  getImageSrc = () => {
    if (this.props.fileURL) {
      return this.props.fileURL;
    } else {
      let imageData = this.state.uploadedFileURL && this.state.uploadedFileURL;
      return imageData;
    }
  };

  renderLoader() {
    return (
      <div style={{ position: "absolute" }}>
        <Spinner size="24px" style={{ color: "#65D2FC" }} />
      </div>
    );
  }

  renderUploaderBackground = () => {
    if (
      this.state.uploadedFileURL ||
      this.props.fileURL ||
      this.state.loading
    ) {
      return (
        <React.Fragment>
          {(this.state.uploadedFileURL || this.props.fileURL) && (
            <img
              src={this.getImageSrc()}
              alt="imgPreview"
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
              className={`${this.props.roundUploader && "roundUploaderImg"}`}
            />
          )}
          {this.state.loading && this.renderLoader()}
        </React.Fragment>
      );
    } else {
      return (
        <BackupIcon
          style={{
            color: "white",
          }}
        />
      );
    }
  };

  renderDocUploader = () => {
    if (this.state.loading) {
      return this.renderLoader();
    } else {
      return (
        <BackupIcon
          style={{
            color: "white",
          }}
        />
      );
    }
  };

  onFileRejected = (rejections) => {
    console.log("rejected uploads:", rejections);
    window.NotificationUtils.showError(
      window.t("somethingWentWrongWhileUploading")
    );
  };

  render() {
    let { accept } = this.props;
    if (accept === "images") {
      accept = ["image/png", "image/jpeg"];
    } else if (accept === "documents") {
      accept = [
        "image/png",
        "image/jpeg",
        "image/gif",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
        "audio/mp3",
        "video/mp4",
        "video/x-msvideo",
      ];
    }

    return (
      <React.Fragment>
        <Dropzone
          onDrop={this.onFileChange}
          onDropRejected={this.onFileRejected}
          accept={accept}
          maxSize={this.props.maxSize ?? 10 * 1024 * 1024}
          multiple={this.props.multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div
                className={`${
                  this.props.roundUploader
                    ? "roundUploaderContainer"
                    : "uploaderMainContainer"
                } ${this.state.selectedFileForUpload && "arenaFileUploaded"} `}
              >
                {this.props.docUploader
                  ? this.renderDocUploader()
                  : this.renderUploaderBackground()}
              </div>
            </div>
          )}
        </Dropzone>
      </React.Fragment>
    );
  }
}
