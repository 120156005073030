import React from "react";
import CommonStructure from "../../common/mainStructure/main";
import ClientNameLarge from "../commentClientNamelarge/commentClientNameLarge";
import CategoryAndComments from "../categoryListItem/categoryListItem";
import Typography from "@material-ui/core/Typography";
import ControlPanel from "../controlPanel/controlPanel";
import { isBrowser, isMobile, isMobileOnly } from "react-device-detect";
import Auth from "../../common/auth";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@material-ui/core/CircularProgress";
export default class Categories extends CommonStructure {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: [],
      loading: true,
      accountDetails: null,
      showHeader: true,
    };
    this.backgroundImg = "/assets/img/building.png";
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    let path = this.props.history.location.pathname;
    let routes = path.split("/");
    let slug = routes[1];
    if (slug === "") {
      return this.props.history.replace("/");
    }

    try {
      let accountDetails = await this.getAccountDetails(slug);

      if (!accountDetails) {
        return this.props.history.replace("/");
      }
      Auth.storeAccountDetails(accountDetails);
      this.backgroundImg =
        accountDetails?.cover?.original || "/assets/img/building.png";
      await this.fetchCategories(accountDetails);
      this.fetchUserDetails();
      this.setState({
        accountDetails,
        loading: false,
      });
    } catch (e) {
      this.props.history.replace("/");
      console.error(e);
    }
  }

  getAccountDetails = async (slug) => {
    try {
      let accountDetails = await window.Arena.database.fetchAccountDetails(
        slug
      );

      if (!accountDetails || accountDetails.data.items.length < 1) {
        //TODO Do something

        return;
      }
      let accountItems = [];
      for (let item of accountDetails.data.items) {
        if (item.slug === slug) {
          accountItems.push(item);
        }
      }
      return accountItems[0];
    } catch (e) {
      console.error(e);
    }
  };

  fetchCategories = async (accountDetails) => {
    try {
      let offset = this.state.categoryData.length;
      let data = await window.Arena.database.fetchCategoriesRelatedToAccount(
        accountDetails.id,
        offset
      );

      if (data.data && !data.data.items.length) {
        this.setState({
          loading: false,
          showEmptyPlaceholder: true,
        });
      }
      await this.getCategoryActivityData(data.data.items);
      window.categoryCount = data.data && data.data.items && data.data.count;
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  getCategoryActivityData = async (categories) => {
    let data = [];
    for (let category of categories) {
      let categoryActivityData = await window.Arena.database.fetchCategoryActivity(
        category.id
      );

      category.activityCount = categoryActivityData.data.activityCount || 0;
      data.push(category);
    }
    data = this.state.categoryData.concat(data);
    this.setState({
      categoryData: data,
      loading: false,
    });
  };

  fetchUserDetails = () => {
    let userDetails = Auth.getUserDetails();
    this.setState({
      userDetails: userDetails,
      loading: false,
    });
  };

  onClickCategoryListItem = (item) => {
    this.props.history.push(
      `/${this.state.accountDetails.slug}/category-discussion?categoryId=${item.id}`
    );
  };

  renderCategoryList = () => {
    let stateCategoryData =
      this.state.categoryData && Object.assign([], this.state.categoryData);
    return (
      <InfiniteScroll
        dataLength={stateCategoryData.length} //This is important field to render the next data
        next={() => this.fetchCategories(this.state.accountDetails)}
        hasMore={window.categoryCount > stateCategoryData.length}
        loader={
          <div className="textAlignCenter">
            <Spinner
              style={{ marginBottom: "24px", color: "#65D2FC" }}
              size="22px"
            />
          </div>
        }
        endMessage={
          <Typography
            variant="subtitle1"
            className="bold blueColor textAlignCenter"
            style={{ marginBottom: "24px" }}
          >
            {window.t("noMoreCategoriesToShow")}
          </Typography>
        }
      >
        {stateCategoryData &&
          stateCategoryData.map((item, index) => {
            return (
              <div key={index} className="categoriesListItemContainer">
                <CategoryAndComments
                  index={index}
                  data={item}
                  onClickCategoryListItem={(e) =>
                    this.onClickCategoryListItem(item)
                  }
                  t={this.props.t}
                />
              </div>
            );
          })}
      </InfiniteScroll>
    );
  };

  renderControlPanel = () => {
    return (
      <React.Fragment>
        <ControlPanel
          history={this.props.history}
          placement={isMobile ? "top" : "left"}
          t={this.props.t}
          canAdminister={this.state.accountDetails?.canAdminister || false}
        />
      </React.Fragment>
    );
  };

  renderTopContent = () => {
    if (!isMobileOnly) {
      return (
        <React.Fragment>
          <div className="categoriesTopContent">
            <ClientNameLarge
              name={this.state.accountDetails && this.state.accountDetails.name}
              isLarge={true}
              imgURL={this.state.accountDetails?.icon?.original || ""}
            />
          </div>
        </React.Fragment>
      );
    }
  };
  renderTopContentForMobile = () => {
    if (isMobileOnly) {
      return (
        <div className="categoriesTopContent">
          <ClientNameLarge
            name={this.state.accountDetails && this.state.accountDetails.name}
            isLarge={true}
            imgURL={this.state.accountDetails?.icon?.original || ""}
          />
        </div>
      );
    }
  };
  renderBottomContent = () => {
    return (
      <React.Fragment>
        <div className="categoryListContainer reverse">
          {this.renderTopContentForMobile()}
          <div style={{ marginBottom: "26px" }}>
            <Typography
              role="heading"
              aria-level="2"
              variant="h5"
              className="redColor bold textAlignEnd categoryHeaderTitle"
            >
              {this.props.t("categoryHeader")}
            </Typography>
          </div>
          {this.renderCategoryList()}
        </div>
        {this.renderControlPanel()}
      </React.Fragment>
    );
  };
}
