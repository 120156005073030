import constants from "../constants";
import Auth from "../components/common/auth";
const axios = require("axios");
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

async function login(data) {
  return axios
    .post(ADMIN_API_URL + "auth/login", {
      email: data.email,
      password: data.password,
      recaptchaToken: data.recaptchaToken,
    })
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred database:", error);
      throw error;
    });
}

async function loginWithFacebook(data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + "auth/login/facebook",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function loginWithGoogle(data) {
  let token = Auth.getToken();

  return axios({
    method: "post",
    url: ADMIN_API_URL + "auth/login/google",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function logout() {
  return axios
    .post(ADMIN_API_URL + "auth/logout")
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function signup(data) {
  return axios
    .post(ADMIN_API_URL + "auth/signup", {
      name: data.name,
      email: data.email,
      password: data.password,
      account: data.account,
      recaptchaToken: data.recaptchaToken,
    })
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function guestSignup() {
  return axios
    .post(ADMIN_API_URL + "auth/signup/guest")
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch((error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

function isLoggedIn() {
  try {
    let userToken = localStorage.getItem(constants.STORAGE_KEYS.USER_TOKEN);

    if (!userToken) {
      return false;
    }
    return { userToken };
  } catch (e) {
    return e;
  }
}

async function lostPassword(data) {
  return axios
    .post(ADMIN_API_URL + "auth/lost-password", {
      email: data.email,
      returnUrl: data.returnUrl,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return lostPassword(data);
      }
      throw error;
    });
}

async function resetPassword(data) {
  return axios
    .post(ADMIN_API_URL + "auth/reset-password", {
      password: data.password,
      token: data.token,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return resetPassword(data);
      }
      throw error;
    });
}

async function fetchAccounts(offset) {
  return axios
    .get(ADMIN_API_URL + `accounts?offset=${offset}`)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function fetchUsers() {
  return axios
    .get(ADMIN_API_URL + "users")
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchUsers();
      }
      throw error;
    });
}

async function saveAccount(data) {
  return axios
    .post(ADMIN_API_URL + "accounts", {
      data,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return saveAccount(data);
      }
      throw error;
    });
}

async function fetchAccountWithId(accountId) {
  return axios
    .get(ADMIN_API_URL + `accounts/${accountId}`)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchAccountWithId(accountId);
      }
      throw error;
    });
}

async function fetchAccountUsers(accountId, search) {
  return axios({
    method: "get",
    url:
      constants.ADMIN_API_URL +
      `accounts/${accountId}/users${
        search ? `?search=${encodeURIComponent(search)}` : ""
      }`,
    headers: { Authorization: `Bearer ${Auth.getToken()}` },
  }).catch(async (error) => {
    console.log("fetchAccountUsers: An error occurred:", error);
    if (error.response.status === 401) {
      await refreshAuthToken();
      return fetchAccountUsers(accountId);
    }
    throw error;
  });
}

async function fetchUserWithActivityInAccount(accountId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `accounts/${accountId}/users`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchUserWithActivityInAccount(accountId);
      }
      throw error;
    });
}

async function fetchCategoriesRelatedToAccount(accountId, offset) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url:
      constants.ADMIN_API_URL +
      `accounts/${accountId}/categories?offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchCategoriesRelatedToAccount(accountId);
      }
      throw error;
    });
}

async function fetchSubjectsRelatedToAccount(accountId, params) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: ADMIN_API_URL + `accounts/${accountId}/subjects`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchSubjectsRelatedToAccount(accountId, params);
      }
      throw error;
    });
}

async function saveUser(data) {
  return axios
    .post(ADMIN_API_URL + "users", {
      data,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return saveUser(data);
      }
      throw error;
    });
}

async function fetchUserWithId(userId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `users/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchUserWithId(userId);
      }
      throw error;
    });
}

async function changeUserPassword(data, userId) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `users/${userId}/password`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return changeUserPassword(data, userId);
      }
      throw error;
    });
}

async function fetchUserComments(userId, offset) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url:
      constants.ADMIN_API_URL +
      `users/${userId}/comments?deleted=false&offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchUserComments(userId, offset);
      }
      throw error;
    });
}

async function saveGroup(data) {
  return axios
    .post(ADMIN_API_URL + `groups`, {
      data,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return saveGroup(data);
      }
      throw error;
    });
}

async function fetchGroups(groupId) {
  return axios
    .get(ADMIN_API_URL + `groups/${groupId}`)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchGroups(groupId);
      }
      throw error;
    });
}

async function deleteGroup(groupId) {
  return axios
    .delete(ADMIN_API_URL + `groups/${groupId}`)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return deleteGroup(groupId);
      }
      throw error;
    });
}

async function addUserToGroup(data, groupId) {
  return axios
    .post(ADMIN_API_URL + `groups/${groupId}/users`, {
      data,
    })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return addUserToGroup(data, groupId);
      }
      throw error;
    });
}

async function createCategories(data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `categories`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return createCategories(data);
      }
      throw error;
    });
}

async function editCategories(data, categoryId) {
  let token = Auth.getToken();

  return axios({
    method: "put",
    url: constants.ADMIN_API_URL + `categories/${categoryId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return editCategories(data, categoryId);
      }
      throw error;
    });
}

async function fetchCategoryActivity(categoryId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `categories/${categoryId}/activity`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchCategoryActivity(categoryId);
      }
      throw error;
    });
}

async function fetchCategoryWithId(categoryId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `categories/${categoryId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchCategoryWithId(categoryId);
      }
      throw error;
    });
}

async function deleteCategory(categoryId) {
  let token = Auth.getToken();
  return axios({
    method: "delete",
    url: constants.ADMIN_API_URL + `categories/${categoryId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return deleteCategory(categoryId);
      }
      throw error;
    });
}

async function uploadImage(data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `uploads/image`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return uploadImage(data);
      }
      throw error;
    });
}

async function uploadDocument(data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `uploads/document`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return uploadDocument(data);
      }
      throw error;
    });
}

async function saveSubject(data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `subjects`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return saveSubject(data);
      }
      throw error;
    });
}

async function editSubject(data, subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "put",
    url: constants.ADMIN_API_URL + `subjects/${subjectId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return editSubject(data, subjectId);
      }
      throw error;
    });
}

async function fetchSubjectWithId(subjectId) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `subjects/${subjectId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchSubjectWithId(subjectId);
      }
      throw error;
    });
}

async function deleteSubject(subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "delete",
    url: constants.ADMIN_API_URL + `subjects/${subjectId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return deleteSubject(subjectId);
      }
      throw error;
    });
}

async function commentOnSubject(data, subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + `subjects/${subjectId}/comments`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return commentOnSubject(data, subjectId);
      }
      throw error;
    });
}

async function fetchSubjectComments(subjectId, offset) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: ADMIN_API_URL + `subjects/${subjectId}/comments?offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchSubjectComments(subjectId, offset);
      }
      throw error;
    });
}

async function voteOnSubject(data, subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + `subjects/${subjectId}/votes`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return voteOnSubject(data, subjectId);
      }
      throw error;
    });
}

async function fetchSubjectReport(subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `subjects/${subjectId}/report`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchSubjectReport(subjectId);
      }
      throw error;
    });
}

async function downloadSubjectReport(subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `subjects/${subjectId}/report/download`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return downloadSubjectReport(subjectId);
      }
      throw error;
    });
}
async function likeComment(commentId, data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + `comments/${commentId}/like/`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return likeComment(commentId, data);
      }
      throw error;
    });
}

async function deleteComment(commentId) {
  let token = Auth.getToken();
  return axios({
    method: "delete",
    url: ADMIN_API_URL + `comments/${commentId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return deleteComment(commentId);
      }
      throw error;
    });
}

async function refreshAuthToken() {
  let refreshToken = Auth.getRefreshToken();
  let data = {
    token: refreshToken,
  };
  return axios({
    method: "post",
    url: ADMIN_API_URL + `auth/refresh`,
    data,
  })
    .then((response) => {
      Auth.storeUserData(response.data);
      return response;
    })
    .catch((error) => {
      console.log("-- REFRESH ERROR:", error);
      logout();
      window.localStorage.clear();
      window.location.reload();
    });
}

async function fetchAccountDetails(slug) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: ADMIN_API_URL + `accounts`,
    params: {
      search: slug, //TODO : To be changed when slug will be present in URL
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error && error.response && error.response.status === 401) {
        await refreshAuthToken();
        return fetchAccountDetails(slug);
      }
      throw error;
    });
}

async function fetchAccountDetailsForAdmin(slug) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: ADMIN_API_URL + `accounts`,
    params: {
      search: slug, //TODO : To be changed when slug will be present in URL
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchAccountDetails;
      }
      throw error;
    });
}

async function downloadDiscussSubjectReport(subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `subjects/${subjectId}/report/download`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return downloadDiscussSubjectReport(subjectId);
      }
      throw error;
    });
}

async function updateUserDetail(data, userId) {
  let token = Auth.getToken();
  return axios({
    method: "put",
    url: ADMIN_API_URL + `users/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return updateUserDetail(data, userId);
      }
      throw error;
    });
}

async function fetchChats() {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `chats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchChats;
      }
      throw error;
    });
}

async function fetchAllChatsWithChatId(chatId, offset) {
  let token = Auth.getToken();
  return axios({
    method: "get",
    url: ADMIN_API_URL + `chats/${chatId}/messages?offset=${offset} `,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchAllChatsWithChatId(chatId, offset);
      }
      throw error;
    });
}

async function sendChatMessageToChatId(data, chatId) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + `chats/${chatId}/messages `,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return sendChatMessageToChatId(data, chatId);
      }
      throw error;
    });
}

async function fetchAccountActivity(accountId) {
  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `accounts/${accountId}/activity`,
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      throw error;
    });
}

async function sendChatMessageToSubjectId(data, subjectId) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: ADMIN_API_URL + `subjects/${subjectId}/chats`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return sendChatMessageToSubjectId(data, subjectId);
      }
      throw error;
    });
}

async function getSubjectStats(subjectId) {
  let token = Auth.getToken();

  return axios({
    method: "get",
    url: constants.ADMIN_API_URL + `subjects/${subjectId}/stats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchSubjectWithId(subjectId);
      }
      throw error;
    });
}

async function socialTracker(subjectId, data) {
  let token = Auth.getToken();
  return axios({
    method: "post",
    url: constants.ADMIN_API_URL + `tracker/share/subjects/${subjectId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return uploadDocument(data);
      }
      throw error;
    });
}

async function fetchGroupsRelatedToAccount(accountId, offset) {
  let token = Auth.getToken();
  if (!accountId) {
    return;
  }
  return axios({
    method: "get",
    url: ADMIN_API_URL + `accounts/${accountId}/groups?offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      let result = response.data;
      return result;
    })
    .catch(async (error) => {
      console.log("An error occurred:", error);
      if (error.response.status === 401) {
        await refreshAuthToken();
        return fetchGroupsRelatedToAccount(accountId);
      }
      throw error;
    });
}

let database = {
  login,
  signup,
  guestSignup,
  isLoggedIn,
  lostPassword,
  resetPassword,
  fetchAccounts,
  saveAccount,
  fetchAccountWithId,
  fetchUserWithActivityInAccount,
  fetchGroupsRelatedToAccount,
  fetchCategoriesRelatedToAccount,
  fetchSubjectsRelatedToAccount,
  fetchUsers,
  fetchAccountUsers,
  saveUser,
  fetchUserWithId,
  changeUserPassword,
  fetchUserComments,
  saveGroup,
  fetchGroups,
  deleteGroup,
  addUserToGroup,
  createCategories,
  fetchCategoryWithId,
  deleteCategory,
  uploadImage,
  uploadDocument,
  saveSubject,
  fetchSubjectWithId,
  deleteSubject,
  commentOnSubject,
  fetchSubjectComments,
  voteOnSubject,
  fetchSubjectReport,
  downloadSubjectReport,
  editCategories,
  logout,
  loginWithFacebook,
  loginWithGoogle,
  likeComment,
  deleteComment,
  editSubject,
  fetchCategoryActivity,
  fetchAccountDetails,
  fetchAccountDetailsForAdmin,
  downloadDiscussSubjectReport,
  updateUserDetail,
  fetchChats,
  fetchAllChatsWithChatId,
  sendChatMessageToChatId,
  fetchAccountActivity,
  sendChatMessageToSubjectId,
  getSubjectStats,
  socialTracker,
};

export default database;
