import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

export default class ReadMoreComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  onClickReadMore = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  showReadMore = () => {
    // let text = "";
    // if (this.props.textData) {
    //   text = this.props.textData;
    // } else {
    //   text = this.props.text;
    // }
    // let wordLength = text && text.length;
    // if (wordLength > 500) {
    //   return true;
    // } else {
    //   return false;
    // }
    return false; // disabling this functionality - doesn't work consistenly
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Typography
            tabIndex="0"
            aria-label={this.props.text}
            variant="body1"
            className={`dullWhite textAlignEnd reverse fontSize18 wordBreak-word ${
              this.props.className ?? ""
            }`}
          >
            {this.props.text || ""}
          </Typography>
        </div>
        {this.showReadMore() ? (
          <Button
            disableRipple={true}
            size="small"
            fullWidth={true}
            id="readMoreAndIcon"
            tabIndex="0"
            aria-label={
              this.state.showMore ? window.t("showLess") : window.t("readMore")
            }
            onClick={this.onClickReadMore}
            className="reverse"
          >
            <Icon
              className={
                this.state.showMore ? "fas fa-angle-up" : "fas fa-angle-down"
              }
              style={{ color: "#65D2FC", fontSize: "14px" }}
            ></Icon>
            <Typography
              className="textAlignEnd reverse blueColor"
              variant="body1"
            >
              <Link>
                {this.state.showMore
                  ? window.t("showLess")
                  : window.t("readMore")}
              </Link>
            </Typography>
          </Button>
        ) : null}
      </React.Fragment>
    );
  }
}
